import {
  POST_NAME,
  POST_EMAIL,
  NEXT_SCREEN,
  POST_SUB,
  POST_MESSAGE
} from "../pages/types";

const initialState = {
  menuData: [],
  logoData: [],
  landingData: [],
  aboutData: [],
  contactData:  [],
  jobData: [],
  servicesData:  [],
  clientData: [],
  orgData: {},
  imagesData: [],
  item: { name: "naga from client..." },
  name: "nagavardhana",
  
  email: "",
  sub: "",
  message: "",
  nextScreen: ""
};

export default function (state = initialState, action) {
  console.log("POST REDUCER action print nexe screen >>>>>>>");
  console.log(action.payload);
  switch (action.type) {
    case POST_NAME:
      console.log("POST_NAME >>>>>");
      return {
        ...state,
        name: action.payload.name,
      };
    case POST_EMAIL:
      console.log("POST_EMAIL >>>>>");
      return {
        ...state,
        email: action.payload.email,
      };
    case POST_SUB:
        console.log("POST_SUB >>>>>");
        return {
          ...state,
          sub: action.payload.sub,
        };
    case POST_MESSAGE:
          console.log("POST_MESSAGE >>>>>");
          return {
            ...state,
            message: action.payload.message,
          };
    case NEXT_SCREEN:
      console.log("NEXT_SCREEN NAV BAR>>>>>" + action.payload.nextScreen);
      return {
        ...state,
        nextScreen: action.payload.nextScreen,
      };
   default:
        return state;
  }
}



