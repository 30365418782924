import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";

const initialState = {};

const middleware = [thunk];

//const store = createStore(() => [], {}, applyMiddleware());

/**  Second Approach
const applyMiddlewareConst = applyMiddleware(...middleware);


const composeEnhancers =
window.__REDUX_DEVTOOLS_EXTENSION__  || compose;

const store = createStore(
  rootReducer,
  initialState,
	composeEnhancers(applyMiddlewareConst)
);
 */
const preloadedState = window.__REDUX_STATE__;
/** to fix errors in saffari browser div */
const store = createStore(
  rootReducer,
  //initialState,
  preloadedState,
  applyMiddleware(...middleware)
  // compose(
  //   applyMiddleware(...middleware),
  //   window.navigator.userAgent.includes("Chrome")
  //     ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
  //         window.__REDUX_DEVTOOLS_EXTENSION__()
  //     : compose
  // )
);

/**  Original approach
const store = createStore(
    rootReducer, 
    initialState, 
    compose(
      applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
    //applyMiddleware(...middleware)
    );
*/
export default store;
