import {
  NEXT_SCREEN,
  POST_NAME,
  POST_EMAIL,
  ADD_SRP,
  UPDATE_SRP,
  FETCH_SRP,
  UPDATE_OLD_SRP,
  UPDATE_SUGGESTIONS,
  UPDATE_CURRENT_FOCUS,
  UPDATE_SRP_SUGGESTIONS,
  REMOVE_SRP,
  VIEW_ALL_SRP,
  ADD_ROOMTYPE,
  FETCH_ROOMTYPE,
  UPDATE_ROOMTYPE,
  UPDATE_OLD_ROOM_TYPE,
  UPDATE_ROOMTYPE_SUGGESTIONS,
  INSERT_ROOMTYPE_SUGGESTIONS,
  UPDATE_ROOMTYPE_CURRENT_FOCUS,
  REMOVE_ROOMTYPE,
  VIEW_ALL_ROOMTYPE,
  UPDATE_DROPDOWN,
  UPDATE_DROPDOWN_NEW,
  UPDATE_ERROR_MESSAGES,
  UPDATE_ROOMTYPE_DROPDOWN_NEW,
  UPDATE_ROOMTYPE_DROPDOWN,
  UPDATE_CLIENT,
  UPDATE_NEW_SRP_CLIENT,
  UPDATE_OLD_SRP_CLIENT,
  UPDATE_ROOMTYPE_ERROR_MESSAGES,
  REMOVE_PROP,
  UPDATE_PROPS_SUGGESTIONS,
  UPDATE_PROPS_DROPDOWN,
  UPDATE_PROPS,
  UPDATE_PROPS_NEW,
  POST_START_DATE,
  POST_END_DATE,
  POST_LOCATION,
  CLEAR_ERROR_MESSAGES,
  MOVE_RESULT,
  UPDATE_CLIENT_DETAILS,
  HIDE_DROPDOWN,
  SHOW_DROPDOWN,
  SHOW_CI_LOADING,
  HIDE_CI_LOADING,
  UPDATE_CLIENT_DETAILS_NEW,
  POST_FLOW,
  POST_FILE,
  POST_ROW,
  CLEAR_ROWS,
  POST_SRP_FILE,
  POST_SRP_ROW,
  CLEAR_SRP_ROWS,
  UPDATE_SRP_UPLOAD_RESULT,
  UPDATE_MOVE_RESULT,
  UPDATE_PROPS_SELECTION,
  UPDATE_OLD_SRP_DESC,
  UPDATE_OLD_ROOMTYPE_DESC,
  UPDATE_OLD_ROOMTYPE_NAME,
  POST_ROOMTYPE_FILE,
  POST_ROOMTYPE_ROW,
  CLEAR_ROOMTYPE_ROWS,
  UPDATE_ROOMTYPE_UPLOAD_RESULT,
  POST_BULKCANCEL_FILE,
  POST_BULKCANCEL_ROW,
  CLEAR_BULKCANCEL_ROWS,
  UPDATE_BULKCANCEL_UPLOAD_RESULT,
  UPDATE_RESULT_TABLE_ANYTEXT,
  UPDATE_RESULT_TABLE_ERROR,
  UPDATE_RESULT_TABLE_ORDER,
  UPDATE_RESULT_FAIL_TABLE_TEXT,
  UPDATE_RESULT_SUCCESS_TABLE_TEXT,
  UPDATE_RESULT_UPLOAD_TABLE_TEXT,
  UPDATE_RESULT_ROOMTYPE_TABLE_TEXT,
  UPDATE_RESULT_SRP_TABLE_TEXT,
  UPDATE_RESULT_BULKCANCEL_TABLE_TEXT,
  CLEAR_FILTER_ARRAY
} from "./types";


export const addSrp = (postData, ownProps) => (dispatch, getState) => {
  console.log("in addSrp >>>>>>>>");

  dispatch({
    type: ADD_SRP,
    payload: postData,
  });
};

export const viewAllSrp = (postData, ownProps) => (dispatch, getState) => {
  console.log("in viewAllSrp >>>>>>>>");

  dispatch({
    type: VIEW_ALL_SRP,
    payload: postData,
  });
};

export const handleSRPFileUpload = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action handleSRPFileUpload  >>>>>>>>>>>>>");
  console.log(postData);

  fetch(getState().postsReduserName.hostname+"/srpfileupload", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(postData),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};


export const handleRoomTypeFileUpload = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action handleRoomTypeFileUpload  >>>>>>>>>>>>>");
  console.log(postData);

  fetch(getState().postsReduserName.hostname+"/roomtypefileupload", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(postData),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const findDupes = (postData, ownProps) => (dispatch, getState) => {
  console.log("in findDupes >>>>>>>>");
  return new Promise((resolve) => { dispatch({
    type: UPDATE_ERROR_MESSAGES,
    payload: postData,
  });
  resolve();
});
};

export const findDupesRoomType = (postData, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in findDupesRoomType >>>>>>>>");
 return new Promise((resolve) => { dispatch({
    type: UPDATE_ROOMTYPE_ERROR_MESSAGES,
    payload: postData,
  });
  resolve();
});
};

export const removeSrp = (index, ownProps) => (dispatch, getState) => {
  console.log("in removeSrp >>>>>>>>");
  dispatch({
    type: REMOVE_SRP,
    index: index,
  });
};

export const fetchSrp = (postData, ownProps) => (dispatch, getState) => {
  console.log("in fetchSrp >>>>>>>>");

  dispatch({
    type: FETCH_SRP,
    payload: {},
  });
};

export const updateFromProp = (rowId, index, value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in updateFromProp >>>>>>>>");
  dispatch({
    type: UPDATE_PROPS,
    rowId: rowId,
    index: index,
    value: value,
  });

  dispatch({
    type: UPDATE_PROPS_SUGGESTIONS,
    value: value,
  });

  dispatch({
    type: UPDATE_PROPS_DROPDOWN,
    value: value,
  });
};

export const updatePropSelection = (rowId, index, value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in updatePropSelection >>>>>>>>");
  dispatch({
    type: NEXT_SCREEN,
    payload: {},
  });
  dispatch({
    type: UPDATE_PROPS_SELECTION,
    rowId: rowId,
    index: index,
    value: value,
  });

  dispatch({
    type: UPDATE_PROPS_SUGGESTIONS,
    value: value,
  });

  dispatch({
    type: UPDATE_PROPS_DROPDOWN,
    value: value,
  });
};

export const updateToProp = (rowId, index, value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in updateToProp >>>>>>>>");

  dispatch({
    type: UPDATE_PROPS_NEW,
    rowId: rowId,
    index: index,
    value: value,
  });

  dispatch({
    type: UPDATE_PROPS_SUGGESTIONS,
    value: value,
  });

  dispatch({
    type: UPDATE_PROPS_DROPDOWN,
    value: value,
  });
};


export const updateTableText = (payload, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in updateTableText >>>>>>>>");
  console.log(payload);
  if(payload.tablenumber==1)
  {
  dispatch({
    type: UPDATE_RESULT_FAIL_TABLE_TEXT,
    payload: payload,
  });
  }else if(payload.tablenumber==2)
  {
  dispatch({
    type: UPDATE_RESULT_SUCCESS_TABLE_TEXT,
    payload: payload,
  });
  }else if(payload.tablenumber==3)
  {
  dispatch({
    type: UPDATE_RESULT_UPLOAD_TABLE_TEXT,
    payload: payload,
  });
  }
  else if(payload.tablenumber==4)
  {
  dispatch({
    type: UPDATE_RESULT_SRP_TABLE_TEXT,
    payload: payload,
  });
  }
  else if(payload.tablenumber==5)
  {
  dispatch({
    type: UPDATE_RESULT_ROOMTYPE_TABLE_TEXT,
    payload: payload,
  });
  }
  else if(payload.tablenumber==6)
  {
  dispatch({
    type: UPDATE_RESULT_BULKCANCEL_TABLE_TEXT,
    payload: payload,
  });
  }
};

export const updateTableOrder = (payload, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in updateTableOrder >>>>>>>>");
  if(payload.tablenumber==1)
  {
  dispatch({
    type: UPDATE_RESULT_FAIL_TABLE_TEXT,
    payload: payload,
  });
  }else if(payload.tablenumber==2)
  {
  dispatch({
    type: UPDATE_RESULT_SUCCESS_TABLE_TEXT,
    payload: payload,
  });
  }else if(payload.tablenumber==3)
  {
  dispatch({
    type: UPDATE_RESULT_UPLOAD_TABLE_TEXT,
    payload: payload,
  });
  }
  else if(payload.tablenumber==4)
  {
  dispatch({
    type: UPDATE_RESULT_SRP_TABLE_TEXT,
    payload: payload,
  });
  }
  else if(payload.tablenumber==5)
  {
  dispatch({
    type: UPDATE_RESULT_ROOMTYPE_TABLE_TEXT,
    payload: payload,
  });
  }
  else if(payload.tablenumber==6)
  {
  dispatch({
    type: UPDATE_RESULT_BULKCANCEL_TABLE_TEXT,
    payload: payload,
  });
  }
};

export const updateTableErrorReason = (payload, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in updateTableErrorReason >>>>>>>>");
  
  dispatch({
    type: UPDATE_RESULT_TABLE_ERROR,
    payload: payload,
  });

};

export const updateTableAnyColumn = (payload, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in updateTableAnyColumn >>>>>>>>");
  dispatch({
    type: UPDATE_RESULT_TABLE_ANYTEXT,
    payload: payload,
  });
};

export const removeProp = (index, ownProps) => (dispatch, getState) => {
  console.log("in removeProp >>>>>>>>");
  dispatch({
    type: REMOVE_PROP,
    index: index,
  });
};

export const updateSrp = (rowId, index, value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in UPDATE_SRP >>>>>>>>");

return new Promise((resolve) => {dispatch({
    type: UPDATE_SRP,
    rowId: rowId,
    index: index,
    value: value,
  });

  dispatch({
    type: UPDATE_SUGGESTIONS,
    value: value,
  });

  dispatch({
    type: UPDATE_DROPDOWN_NEW,
    value: value,
  });
  resolve();
  });
};

export const updateOldSrp = (rowId, index, value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in UPDATE_OLD_SRP >>>>>>>>");

  dispatch({
    type: UPDATE_OLD_SRP,
    rowId: rowId,
    index: index,
    value: value,
  });

  dispatch({
    type: UPDATE_SUGGESTIONS,
    value: value,
  });

  dispatch({
    type: UPDATE_DROPDOWN,
    value: value,
  });
};

export const updateOldSrpClientId = (index, value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in UPDATE_OLD_SRP_CLIENT client>>>>>>>>");

  dispatch({
    type: UPDATE_OLD_SRP_CLIENT,
    index: index,
    value: value,
  });
};

export const updateNewSrpClientId = (index, value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in UPDATE_NEW_SRP_CLIENT client>>>>>>>>");

  dispatch({
    type: UPDATE_NEW_SRP_CLIENT,
    index: index,
    value: value,
  });
};

export const fetchOldSrp = (index, value, ownProps) => (dispatch, getState) => {
  console.log("in UPDATE_OLD_SRP >>>>>>>>");

  dispatch({
    type: UPDATE_DROPDOWN,
    value: value,
  });
};

export const updateSuggestions = (value, ownProps) => (dispatch, getState) => {
  console.log("in updateSuggestions >>>>>>>>");

  dispatch({
    type: UPDATE_SRP_SUGGESTIONS,
    value: value,
  });
};

export const updateCurrentFocus = (index, name, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in UPDATE_CURRENT_FOCUS >>>>>>>>");

  dispatch({
    type: UPDATE_CURRENT_FOCUS,
    index: index,
    name: name,
  });
};

export const addRoomType = (postData, ownProps) => (dispatch, getState) => {
  console.log("in addRoomType >>>>>>>>");

  dispatch({
    type: ADD_ROOMTYPE,
    payload: postData,
  });
};

export const viewAllRoomTypes = (postData, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in viewAllRoomTypes >>>>>>>>");

  dispatch({
    type: VIEW_ALL_ROOMTYPE,
    payload: postData,
  });
};

export const removeRoomType = (index, ownProps) => (dispatch, getState) => {
  console.log("in removeRoomType >>>>>>>>");
  dispatch({
    type: REMOVE_ROOMTYPE,
    index: index,
  });
};

export const fetchRoomType = (postData, ownProps) => (dispatch, getState) => {
  console.log("in fetchRoomType >>>>>>>>");

  dispatch({
    type: FETCH_ROOMTYPE,
    payload: {},
  });
};
export const updateRoomType = (rowId, index, value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in  ACTION updateRoomType >>>>>>>>");
  console.log(value);
  dispatch({
    type: UPDATE_ROOMTYPE,
    rowId: rowId,
    index: index,
    value: value,
  });

  dispatch({
    type: UPDATE_ROOMTYPE_SUGGESTIONS,
    value: value,
  });

  dispatch({
    type: UPDATE_ROOMTYPE_DROPDOWN_NEW,
    value: value,
  });
};

export const updateOldRoomType = (rowId, index, value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in updateOldRoomType >>>>>>>>");

  dispatch({
    type: UPDATE_OLD_ROOM_TYPE,
    rowId: rowId,
    index: index,
    value: value,
  });

  dispatch({
    type: UPDATE_ROOMTYPE_SUGGESTIONS,
    value: value,
  });

  dispatch({
    type: UPDATE_ROOMTYPE_DROPDOWN,
    value: value,
  });
};

export const insertRoomTypeSuggestions = (value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in insertRoomTypeSuggestions >>>>>>>>");

  dispatch({
    type: INSERT_ROOMTYPE_SUGGESTIONS,
    value: value,
  });
};

export const updateRoomTypeCurrentFocus = (index, name, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in updateRoomTypeCurrentFocus >>>>>>>>");

  dispatch({
    type: UPDATE_ROOMTYPE_CURRENT_FOCUS,
    index: index,
    name: name,
  });
};

export const save = (ownProps) => (dispatch, getState) => {
  console.log("in action save  >>>>>>>>>>>>>");
  let name = getState().postsReduserName.name;
  console.log(name);

  fetch(getState().postsReduserName.hostname+"/save", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      name: getState().postsReduserName.name,
      email: getState().postsReduserName.email,
    }),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: {},
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const postName = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postName postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_NAME,
    payload: postData,
  });
};

export const postEmail = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postEmail postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_EMAIL,
    payload: postData,
  });
};


export const downLoadFilterRows = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action downLoadFilterRows postData >>>>>>>>");
  console.log(postData);
  // dispatch({
  //   type: POST_EMAIL,
  //   payload: postData,
  // });
};

export const postStartDate = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postStartDate >>>>>>>>");
  console.log(postData);
  dispatch({
    type: NEXT_SCREEN,
    payload: {},
  });
  dispatch({
    type: POST_START_DATE,
    payload: postData,
  });
  
};

export const postEndDate = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postEndDate >>>>>>>>");
  console.log(postData);
  dispatch({
    type: NEXT_SCREEN,
    payload: {},
  });
  dispatch({
    type: POST_END_DATE,
    payload: postData,
  });
};

export const showDropDown = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action showDropDown >>>>>>>>");
  dispatch({
    type: SHOW_DROPDOWN,
    payload: {},
  });
};

export const hideDropDown = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action hideDropDown >>>>>>>>");
  dispatch({
    type: HIDE_DROPDOWN,
    payload: {},
  });
};

export const showCILoading = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action showCILoading >>>>>>>>");
  dispatch({
    type: SHOW_CI_LOADING,
    payload: {},
  });
};

export const hideCILoading = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action hideCILoading >>>>>>>>");
  dispatch({
    type: HIDE_CI_LOADING,
    payload: {},
  });
};

export const saveProps = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveProps  >>>>>>>>>>>>>");
  const propsMapping = {
    propsMapping:postData,
    sessionToken:getState().postsReduserName.sessionToken
  }
  console.log(propsMapping);
  console.log("hostname from saveProps>>>>>>>>>>>>>");
  console.log(getState().postsReduserName.sessionToken);
  console.log(getState().postsReduserName.hostname);
    fetch(getState().postsReduserName.hostname+"/saveprops", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(propsMapping),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const saveSrps = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveSrps  >>>>>>>>>>>>>");
  console.log(postData);
  dispatch({
    type: NEXT_SCREEN,
    payload: {nextScreen:false},
  });
  const srpMapping = {
    srpMapping:postData,
    sessionToken:getState().postsReduserName.sessionToken
  }
  console.log(srpMapping);
  fetch(getState().postsReduserName.hostname+"/savesrp", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(srpMapping),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const goToRoomMappings = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveSrps  >>>>>>>>>>>>>");
  dispatch({
    type: NEXT_SCREEN,
    payload: {nextScreen:false},
  });
  fetch(getState().postsReduserName.hostname+"/gotoroomtypemappings", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({}),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const goToDatesOrMove = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action goToDatesOrMove >>>>>>>>>>>>>");
  console.log(postData);
  dispatch({
    type: NEXT_SCREEN,
    payload: {nextScreen:false},
  });

  fetch(getState().postsReduserName.hostname+"/gotodatesormove", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({}),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const saveSrpAuto = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveSrps  >>>>>>>>>>>>>");
  console.log(postData);
  const srpMapping = {
    srpMapping:postData,
    sessionToken:getState().postsReduserName.sessionToken
  }
  console.log(srpMapping);
  fetch(getState().postsReduserName.hostname+"/savesrp", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(srpMapping),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");
          dispatch({
            type: NEXT_SCREEN,
            payload: {nextScreen:"save"},
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const saveRoomTypes = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveRoomTypes  >>>>>>>>>>>>>");
  console.log(postData);
  const roomTypeMapping = {
    roomTypeMapping:postData,
    sessionToken:getState().postsReduserName.sessionToken
  }
  fetch(getState().postsReduserName.hostname+"/saveroomtypes", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(roomTypeMapping),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const saveRoomTypesAuto = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveRoomTypes  >>>>>>>>>>>>>");
  console.log(postData);
  const roomTypeMapping = {
    roomTypeMapping:postData,
    sessionToken:getState().postsReduserName.sessionToken
  }
  fetch(getState().postsReduserName.hostname+"/saveroomtypes", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(roomTypeMapping),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          // dispatch({
          //   type: NEXT_SCREEN,
          //   payload: JSON.parse(data),
          // });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const saveDates = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveRoomTypes  >>>>>>>>>>>>>");
  console.log(postData);
  const datesMapping = {
    datesMapping:postData,
    sessionToken:getState().postsReduserName.sessionToken
  }
  fetch(getState().postsReduserName.hostname+"/savedates", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(datesMapping),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const saveDatesRangeForHistory = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveDatesRangeForHistory  >>>>>>>>>>>>>");
  console.log(postData);
  const datesMapping = {
    datesMapping:postData,
    sessionToken:getState().postsReduserName.sessionToken
  }
  dispatch({
    type: NEXT_SCREEN,
    payload: {},
  });
  fetch(getState().postsReduserName.hostname+"/savedatesrangeforhistory", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(datesMapping),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const saveDatesBulkCancelHistory = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveDatesBulkCancelHistory  >>>>>>>>>>>>>");
  console.log(postData);
  const datesMapping = {
    datesMapping:postData,
    sessionToken:getState().postsReduserName.sessionToken
  }
  dispatch({
    type: NEXT_SCREEN,
    payload: {},
  });
  fetch(getState().postsReduserName.hostname+"/datesrangecancelhistory", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(datesMapping),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const dowloadExcelHistory = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action dowloadExcelHistory  >>>>>>>>>>>>>");
  console.log(postData);

  fetch(getState().postsReduserName.hostname+"/historyexcel", {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};


export const moveTransfers = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action moveTransfers  >>>>>>>>>>>>>");
  console.log(postData);

  fetch(getState().postsReduserName.hostname+"/movetransfers", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(postData),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save >>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE >>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const clearErrorMessages = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action clearErrorMessages postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: CLEAR_ERROR_MESSAGES,
    payload: postData,
  });
};

export const postLocation = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postLocation postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_LOCATION,
    payload: postData,
  });
};

export const saveLocation = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveLocation  >>>>>>>>>>>>>");
  console.log(postData);
  const locationMapping = {
    location:postData,
    sessionToken:getState().postsReduserName.sessionToken
  }

  fetch(getState().postsReduserName.hostname+"/savelocation", {
    method: "POST",
    credentials: 'include',
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(locationMapping),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save location>>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE location>>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const postFlow = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postFlow postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_FLOW,
    payload: postData,
  });
};

export const saveFlow = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveFlow  >>>>>>>>>>>>>");
  console.log(getState().postsReduserName.hostname);
  console.log(postData);
  const flowMapping = {
    flow:postData,
    sessionToken:getState().postsReduserName.sessionToken
  }

  fetch(getState().postsReduserName.hostname+"/saveflow", {
    method: "POST",
    credentials: 'include',
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(flowMapping),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save flow>>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE flow>>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const postFile = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postFile postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_FILE,
    payload: postData,
  });
};

export const postRecord = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postRecord postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_ROW,
    payload: postData,
  });
};

export const postSRPFile = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postSRPFile postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_SRP_FILE,
    payload: postData,
  });
};

export const postSRPRecord = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postRecord postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_SRP_ROW,
    payload: postData,
  });
};

export const clearSRPFileRecords =
  (postData, ownProps) => (dispatch, getState) => {
  
    return new Promise((resolve) => {
  
    console.log("in action clearSRPFileRecords postData >>>>>>>>");

    fetch(getState().postsReduserName.hostname + "/clearfilerecords", {
      method: "POST",
      body: {},
    })
      .then(function (r) {
        console.log(r);
        r.text().then(function (data) {
          console.log("Status save file>>>>>>");
          console.log(r.ok);

          if (r.ok) {
            console.log("Dispatching if clearSRPFileRecords >>>>>>");
            dispatch({
              type: CLEAR_SRP_ROWS,
              payload: JSON.parse(data),
            });
            resolve();

          } else {
            console.log("Dispatching else clearSRPFileRecords >>>>>>");
          }

          console.log("Body  >>>>>>");
          console.log(data);
        });
      })
      .then((obj) => console.log(obj));
       
    });
  };

export const clearFileRecords =
  (postData, ownProps) => (dispatch, getState) => {
  
    return new Promise((resolve) => {
  
    console.log("in action clearFileRecords postData >>>>>>>>");

    fetch(getState().postsReduserName.hostname + "/clearfilerecords", {
      method: "POST",
      body: {},
    })
      .then(function (r) {
        console.log(r);
        r.text().then(function (data) {
          console.log("Status save file>>>>>>");
          console.log(r.ok);

          if (r.ok) {
            console.log("Dispatching if clearFileRecords >>>>>>");
            dispatch({
              type: CLEAR_ROWS,
              payload: JSON.parse(data),
            });
            resolve();

          } else {
            console.log("Dispatching else clearFileRecords >>>>>>");
          }

          console.log("Body  >>>>>>");
          console.log(data);
        });
      })
      .then((obj) => console.log(obj));
       
    });
  };

export const saveFile = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action saveFile  >>>>>>>>>>>>>");
  console.log(postData.file);
  const fileMapping = {
    file: postData,
    sessionToken: getState().postsReduserName.sessionToken
  }

  var formData = new FormData();
  formData.append("file", postData.file);
  fetch(getState().postsReduserName.hostname+"/savefile", {
    method: "POST",
    body: formData,
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save file>>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE file>>>>>>");

          dispatch({
            type: NEXT_SCREEN,
            payload: JSON.parse(data),
          });
        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const moveStay = (postData, isFirstTranx, ownProps) => (dispatch, getState) => {
  console.log("in action moveStay  >>>>>>>>>>>>>");
  isFirstTranx ? postData.retry = false : postData.retry = true;
  return new Promise((resolve) => { 

            dispatch({
              type: MOVE_RESULT,
              payload: postData,
              isFirstTranx: isFirstTranx
            });
    
  resolve();
});
   
};

export const updateMoveStatus = (postData, rowId, ownProps) => (dispatch, getState) => {
  console.log("in action updateTransferStatus  >>>>>>>>>>>>>");
  console.log(postData);
  return new Promise((resolve) => { 

            dispatch({
              type: UPDATE_MOVE_RESULT,
              payload: JSON.parse(postData),
              rowId: rowId
            });
    
  resolve();
});
   
};

export const updateSRPUploadResult = (postData, rowId, ownProps) => (dispatch, getState) => {
  console.log("in action updateSRPUploadResult  >>>>>>>>>>>>>"+rowId);
  console.log(postData);
  return new Promise((resolve) => { 

            dispatch({
              type: UPDATE_SRP_UPLOAD_RESULT,
              payload: JSON.parse(postData),
              rowId: rowId
            });
    
  resolve();
});
   
};

export const postRoomTypeFile = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postRoomTypeFile postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_ROOMTYPE_FILE,
    payload: postData,
  });
};

export const postRoomTypeRecord = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postRoomTypeRecord postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_ROOMTYPE_ROW,
    payload: postData,
  });
};

export const clearRoomTypeFileRecords =
  (postData, ownProps) => (dispatch, getState) => {
  
    return new Promise((resolve) => {
  
    console.log("in action clearRoomTypeFileRecords postData >>>>>>>>");

    fetch(getState().postsReduserName.hostname + "/clearfilerecords", {
      method: "POST",
      body: {},
    })
      .then(function (r) {
        console.log(r);
        r.text().then(function (data) {
          console.log("Status save file>>>>>>");
          console.log(r.ok);

          if (r.ok) {
            console.log("Dispatching if clearSRPFileRecords >>>>>>");
            dispatch({
              type: CLEAR_ROOMTYPE_ROWS,
              payload: JSON.parse(data),
            });
            resolve();

          } else {
            console.log("Dispatching else clearSRPFileRecords >>>>>>");
          }

          console.log("Body  >>>>>>");
          console.log(data);
        });
      })
      .then((obj) => console.log(obj));
       
    });
  };

export const updateRoomTypeUploadResult = (postData, rowId, ownProps) => (dispatch, getState) => {
  console.log("in action updateRoomTypeUploadResult  >>>>>>>>>>>>>");
  console.log(postData);
  return new Promise((resolve) => { 

            dispatch({
              type: UPDATE_ROOMTYPE_UPLOAD_RESULT,
              payload: JSON.parse(postData),
              rowId: rowId
            });
    
  resolve();
});
   
};

export const updateClientDetails = (postData, userInput, rowId, ownProps) => (dispatch, getState) => {
  console.log("in action updateClientDetails  >>>>>>>>>>>>>");
  console.log(postData);
  const srp = {
    ratePlanCode:postData
  }

  fetch(getState().postsReduserName.hostname+"/clientdetails", {
    method: "POST",
    credentials: 'include',
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(srp),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save location updateClientDetails>>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE location updateClientDetails>>>>>>");

          return new Promise((resolve) => {
           
          dispatch({
            type: UPDATE_CLIENT_DETAILS,
            payload: JSON.parse(data),
            rowId: rowId
          });
          dispatch({
            type: UPDATE_SUGGESTIONS,
            value: userInput,
          });

          dispatch({
            type: HIDE_CI_LOADING,
            value: {},
          });

          resolve();
          });

        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const updateClientDetailsNewSRP = (postData, userInput, rowId, ownProps) => (dispatch, getState) => {
  console.log("in action updateClientDetailsNewSRP  >>>>>>>>>>>>>");
  console.log(postData);
  const srp = {
    ratePlanCode:postData
  }
  fetch(getState().postsReduserName.hostname+"/clientdetailsnew", {
    method: "POST",
    credentials: 'include',
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(srp),
  })
    .then(function (r) {
      console.log(r);
      r.text().then(function (data) {
        console.log("Status save location updateClientDetailsNew>>>>>>");
        console.log(r.ok);

        if (r.ok) {
          console.log("Dispatching SAVE location updateClientDetailsNew>>>>>>");

          return new Promise((resolve) => {
           
          dispatch({
            type: UPDATE_CLIENT_DETAILS_NEW,
            payload: JSON.parse(data),
            rowId: rowId
          });
          dispatch({
            type: UPDATE_SUGGESTIONS,
            value: userInput,
          });

          dispatch({
            type: HIDE_CI_LOADING,
            value: {},
          });

          resolve();
          });

        } else {
        }

        console.log("Body  >>>>>>");
        console.log(data);
      });
    })

    .then((obj) => console.log(obj));
};

export const updateOldRoomTypeDesc = (rowId, index, value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in UPDATE_OLD_ROOMTYPE_DESC >>>>>>>>");
  console.log(rowId, index, value);
  dispatch({
    type: UPDATE_OLD_ROOMTYPE_DESC,
    rowId: rowId,
    index: index,
    value: value,
  });

};

export const updateOldSrpDesc = (rowId, index, value, ownProps) => (
  dispatch,
  getState
) => {
  console.log("in UPDATE_OLD_SRP_DESC >>>>>>>>");
  console.log(rowId, index, value);
  dispatch({
    type: UPDATE_OLD_SRP_DESC,
    rowId: rowId,
    index: index,
    value: value,
  });
};

export const postBulkCancelFile = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postBulkCancelFile postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_BULKCANCEL_FILE,
    payload: postData,
  });
};

export const postBulkCancelRecord = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postBulkCancelRecord postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_BULKCANCEL_ROW,
    payload: postData,
  });
};
export const clearAllFilters = (payload, ownProps) => (dispatch, getState) => {
  console.log("in action clearAllFilters postData >>>>>>>>");
  console.log(payload);

  dispatch({
    type: CLEAR_FILTER_ARRAY,
    payload: payload,
  });

};

export const clearBulkCancelFileRecords =
  (postData, ownProps) => (dispatch, getState) => {
  
    return new Promise((resolve) => {
  
    console.log("in action clearBulkCanceleFileRecords postData >>>>>>>>");

    fetch(getState().postsReduserName.hostname + "/clearfilerecords", {
      method: "POST",
      body: {},
    })
      .then(function (r) {
        console.log(r);
        r.text().then(function (data) {
          console.log("Status save file>>>>>>");
          console.log(r.ok);

          if (r.ok) {
            console.log("Dispatching if clearSRPFileRecords >>>>>>");
            dispatch({
              type: CLEAR_BULKCANCEL_ROWS,
              payload: JSON.parse(data),
            });
            resolve();

          } else {
            console.log("Dispatching else clearSRPFileRecords >>>>>>");
          }

          console.log("Body  >>>>>>");
          console.log(data);
        });
      })
      .then((obj) => console.log(obj));
       
    });
  };

export const updateBulkCancelUploadResult = (postData, rowId, ownProps) => (dispatch, getState) => {
  console.log("in action updateBulkCancelUploadResult  >>>>>>>>>>>>>");
  console.log(postData);
  return new Promise((resolve) => { 

            dispatch({
              type: UPDATE_BULKCANCEL_UPLOAD_RESULT,
              payload: JSON.parse(postData),
              rowId: rowId
            });
    
  resolve();
});
   
};