export const FETCH_POSTS = "FETCH_POSTS";
export const NEW_POST = "NEW_POST";
export const DECREMENT = "DECREMENT";
export const INCREMENT = "INCREMENT";
export const FETCH_MENUS = "FETCH_MENUS";
export const FETCH_DROPDOWN_MENUS = "FETCH_DROPDOWN_MENUS";
export const FETCH_SEARCHBAR_MENUS = "FETCH_SEARCHBAR_MENUS";
export const FETCH_TOGGLEBAR_MENUS = "FETCH_TOGGLEBAR_MENUS";
export const FETCH_LOGO = "FETCH_LOGO";
export const FETCH_FILTERS = "FETCH_FILTERS";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const VIEW_PRODUCT = "VIEW_PRODUCT";
export const VIEW_CART = "VIEW_CART";
export const APPLY_FILTER = "APPLY_FILTER";
export const PROCESS_FILTERS = "PROCESS_FILTERS";
export const REMOVE_FILTERS = "REMOVE_FILTERS";
export const FILTER_OPEN = "FILTER_OPEN";
export const FILTER_CLOSE = "FILTER_CLOSE";
export const CREATE_SEARCH_QUERY = "CREATE_SEARCH_QUERY";
export const REMOVE_SEARCH_QUERY = "REMOVE_SEARCH_QUERY";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const REMOVE_DEPARTMENT = "REMOVE_DEPARTMENT";
export const ADD_RATINGS = "ADD_RATINGS";
export const REMOVE_RATINGS = "REMOVE_RATINGS";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const SEARCH_CLICKED = "SEARCH_CLICKED";
export const SEARCH_NOT_CLICKED = "SEARCH_NOT_CLICKED";
export const OFFER_TAC = "OFFER_TAC";
export const WARRANTY_DTL = "WARRANTY_DTL";
export const DELIVERY_DTL = "DELIVERY_DTL";
export const PAYMENT_OPTS = "PAYMENT_OPTS";
export const SELLER_DTL = "SELLER_DTL";
export const FETCH_ALL_RR = "FETCH_ALL_RR";
export const FETCH_ALL_QA = "FETCH_ALL_QA";

export const UPDATE_QTY = "UPDATE_QTY";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST";
export const FETCH_COUNTRY_LIST_REQUEST = "FETCH_COUNTRY_LIST_REQUEST";
export const FETCH_COUNTRY_LIST_SUCCESS = "FETCH_COUNTRY_LIST_SUCCESS";
export const FETCH_COUNTRY_LIST_FAIL = "FETCH_COUNTRY_LIST_FAIL";

export const FETCH_ADDRESS = "FETCH_ADDRESS";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const UPDATE_INPUT = "UPDATE_INPUT";
export const UPDATE_DAYS = "UPDATE_DAYS";
export const UPDATE_SLOTS = "UPDATE_SLOTS";
export const ADD_DAYS = "ADD_DAYS";
export const FETCH_CHECKOUT_INFO = "FETCH_CHECKOUT_INFO";
export const FETCH_DEFAULT_ADDRESS = "FETCH_DEFAULT_ADDRESS";
export const FETCH_DEFAULT_PAYMENT = "FETCH_DEFAULT_PAYMENT";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const SELECT_PAYMENT_METHOD_FOR_EDIT = "SELECT_PAYMENT_METHOD_FOR_EDIT";
export const SUBMIT_PAYMENT_METHOD_CHANGES = "SUBMIT_PAYMENT_METHOD_CHANGES";
export const FETCH_ISSUER_DETAILS = "FETCH_ISSUER_DETAILS";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const SELECT_ADDRESS = "SELECT_ADDRESS";
export const SELECT_PAYMENT_METHOD = "SELECT_PAYMENT_METHOD";
export const FETCH_ADDRESS_BOOK = "FETCH_ADDRESS_BOOK";
export const FETCH_ADDRESS_BOOK_REQUEST = "FETCH_ADDRESS_BOOK_REQUEST";
export const FETCH_ADDRESS_BOOK_SUCCESS = "FETCH_ADDRESS_BOOK_SUCCESS";
export const FETCH_ADDRESS_BOOK_FAIL = "FETCH_ADDRESS_BOOK_FAIL";
export const FETCH_DAYS = "FETCH_DAYS";
export const FETCH_DAYS_REQUEST = "FETCH_DAYS_REQUEST";
export const FETCH_DAYS_SUCCESS = "FETCH_DAYS_SUCCESS";
export const FETCH_DAYS_FAIL = "FETCH_DAYS_FAIL";
export const UPDATE_AVAILABLE_DAYS = "UPDATE_AVAILABLE_DAYS";
export const UPDATE_AVAILABLE_SLOTS = "UPDATE_AVAILABLE_SLOTS";
export const UPDATE_ADDRESS_TYPE = "UPDATE_ADDRESS_TYPE";

export const UPDATE_AVAILABLE_ADDRESS_TYPE = "UPDATE_AVAILABLE_ADDRESS_TYPE";
export const UPDATE_SELECTED_ADDRESS_TYPE = "UPDATE_SELECTED_ADDRESS_TYPE";
export const UPDATE_DEFAULT_ADDRESS_FLAG = "UPDATE_DEFAULT_ADDRESS_FLAG";
export const FETCH_ADDRESS_TYPES_REQUEST = "FETCH_ADDRESS_TYPES_REQUEST";
export const FETCH_ADDRESS_TYPES_SUCCESS = "FETCH_ADDRESS_TYPES_SUCCESS";
export const FETCH_ADDRESS_TYPES_FAIL = "FETCH_ADDRESS_TYPES_FAIL";
export const FETCH_ADDRESS_TYPES = "FETCH_ADDRESS_TYPES";
export const ADD_ADDRESS_TO_INITIALSETUP = "ADD_ADDRESS_TO_INITIALSETUP";

export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCH_PAYMENT_METHODS_REQUEST = "FETCH_PAYMENT_METHODS_REQUEST";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAIL = "FETCH_PAYMENT_METHODS_FAIL";

export const CREATE_ADDRESS_BOOK_REQUEST = "CREATE_ADDRESS_BOOK_REQUEST";
export const CREATE_ADDRESS_BOOK_SUCCESS = "CREATE_ADDRESS_BOOK_SUCCESS";
export const CREATE_ADDRESS_BOOK_FAIL = "CREATE_ADDRESS_BOOK_FAIL";
export const CREATE_INITIALSETUP = "CREATE_INITIALSETUP";
export const CREATE_INITIALSETUP_DONE = "CREATE_INITIALSETUP_DONE";
export const ADDRESS_BOOK_ERRORS = "ADDRESS_BOOK_ERRORS";
export const PAYMENT_METHODS_RELOAD = "PAYMENT_METHODS_RELOAD";
export const PAYMENT_METHOD_ERRORS = "PAYMENT_METHOD_ERRORS";
export const CREATE_PAYMENT_METHOD_FAIL = "CREATE_PAYMENT_METHOD_FAIL";
export const PAYMENT_METHODS_RELOAD_DONE = "PAYMENT_METHODS_RELOAD_DONE";

export const CREATE_SHIPPING_ADDRESS_REQUEST =
  "CREATE_SHIPPING_ADDRESS_REQUEST";
export const CREATE_SHIPPING_ADDRESS = "CREATE_SHIPPING_ADDRESS";
export const CREATE_SHIPPING_ADDRESS_FAIL = "CREATE_SHIPPING_ADDRESS_FAIL";
export const CREATE_SHIPPING_ADDRESS_ERROR = "CREATE_SHIPPING_ADDRESS_ERROR";
export const CREATE_SHIPPING_ADDRESS_SUCCESS =
  "CREATE_SHIPPING_ADDRESS_SUCCESS";
export const CREATE_SHIPPING_ADDRESS_RELOAD = "CREATE_SHIPPING_ADDRESS_RELOAD";
export const CREATE_SHIPPING_ADDRESS_RELOAD_DONE =
  "CREATE_SHIPPING_ADDRESS_RELOAD_DONE";
export const CREATE_BILLING_ADDRESS = "CREATE_BILLING_ADDRESS";
export const CREATE_BILLING_ADDRESS_REQUEST = "CREATE_BILLING_ADDRESS";
export const CREATE_BILLING_ADDRESS_ERROR = "CREATE_BILLING_ADDRESS";
export const CREATE_BILLING_ADDRESS_SUCCESS = "CREATE_BILLING_ADDRESS_SUCCESS";
export const CREATE_BILLING_ADDRESS_FAIL = "CREATE_BILLING_ADDRESS_FAIL";
export const CREATE_BILLING_ADDRESS_RELOAD_DONE =
  "CREATE_BILLING_ADDRESS_RELOAD_DONE";
export const CREATE_BILLING_ADDRESS_RELOAD = "CREATE_BILLING_ADDRESS_RELOAD";
export const CREATE_CURRENT_PAYMENT_METHOD_REQUEST =
  "CREATE_CURRENT_PAYMENT_METHOD_REQUEST";
export const CREATE_CURRENT_PAYMENT_METHOD = "CREATE_CURRENT_PAYMENT_METHOD";
export const CREATE_CURRENT_PAYMENT_METHOD_SUCCESS =
  "CREATE_CURRENT_PAYMENT_METHOD_SUCCESS";
export const CREATE_CURRENT_PAYMENT_METHOD_ERROR =
  "CREATE_CURRENT_PAYMENT_METHOD_ERROR";
export const CREATE_CURRENT_PAYMENT_METHOD_FAIL =
  "CREATE_CURRENT_PAYMENT_METHOD_FAIL";
export const FETCH_CURRENT_PAYMENT_METHOD_REQUEST =
  "CREATE_CURRENT_PAYMENT_METHOD_REQUEST";
export const FETCH_CURRENT_PAYMENT_METHOD = "CREATE_CURRENT_PAYMENT_METHOD";
export const FETCH_CURRENT_PAYMENT_METHOD_SUCCESS =
  "CREATE_CURRENT_PAYMENT_METHOD_SUCCESS";
export const FETCH_CURRENT_PAYMENT_METHOD_ERROR =
  "CREATE_CURRENT_PAYMENT_METHOD_ERROR";
export const FETCH_CURRENT_PAYMENT_METHOD_FAIL =
  "CREATE_CURRENT_PAYMENT_METHOD_FAIL";

export const CURRENT_PAYMENT_METHOD_RELOAD = "CURRENT_PAYMENT_METHOD_RELOAD";
export const CURRENT_PAYMENT_METHOD_RELOAD_DONE =
  "CURRENT_PAYMENT_METHOD_RELOAD_DONE";

export const FETCH_CART_ITEMS_REQUEST = "FETCH_CART_ITEMS_REQUEST";
export const FETCH_CART_ITEMS = "FETCH_CART_ITEMS";
export const FETCH_CART_ITEMS_SUCCESS = "FETCH_CART_ITEMS_SUCCESS";
export const FETCH_CART_ITEMS_FAIL = "FETCH_CART_ITEMS_FAIL";
export const FETCH_CART_ITEMS_ERROR = "FETCH_CART_ITEMS_ERROR";
export const FETCH_CART_ITEMS_RELOAD = "FETCH_CART_ITEMS_RELOAD";
export const FETCH_CART_ITEMS_RELOAD_DONE = "FETCH_CART_ITEMS_RELOAD_DONE";

export const UPDATE_CART_ITEMS_QTY = "UPDATE_CART_ITEMS_QTY";
export const UPDATE_CART_ITEMS_QTY_REQUEST = "UPDATE_CART_ITEMS_QTY_REQUEST";
export const UPDATE_CART_ITEMS_QTY_SUCCESS = "UPDATE_CART_ITEMS_QTY_SUCCESS";
export const UPDATE_CART_ITEMS_QTY_FAIL = "UPDATE_CART_ITEMS_QTY_FAIL";
export const UPDATE_CART_ITEMS_QTY_ERROR = "UPDATE_CART_ITEMS_QTY_ERROR";
export const UPDATE_CART_ITEMS_QTY_RELOAD = "UPDATE_CART_ITEMS_QTY_RELOAD";
export const UPDATE_CART_ITEMS_QTY_DONE = "UPDATE_CART_ITEMS_QTY_DONE";

export const PLACE_ORDER_REQUEST = "PLACE_ORDER_REQUEST";
export const PLACE_ORDER = "PLACE_ORDER";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAIL = "PLACE_ORDER_FAIL";
export const PLACE_ORDER_ERROR = "PLACE_ORDER_ERRO";
export const PLACE_ORDER_RELOAD = "PLACE_ORDER_RELOAD";
export const PLACE_ORDER_RELOAD_DONE = "PLACE_ORDER_RELOAD_DONE";
export const ORDER_CONFIRMATION = "ORDER_CONFIRMATION";

export const FETCH_PRODUCT_DETAILS_REQUEST = "FETCH_PRODUCT_DETAILS_REQUEST";
export const FETCH_PRODUCT_DETAILS = "FETCH_PRODUCT_DETAILS";
export const FETCH_PRODUCT_DETAILS_SUCCESS = "FETCH_PRODUCT_DETAILS_SUCCESS";
export const FETCH_PRODUCT_DETAILS_FAIL = "FETCH_PRODUCT_DETAILS_FAIL";
export const FETCH_PRODUCT_DETAILS_ERROR = "FETCH_PRODUCT_DETAILS_ERROR";
export const FETCH_PRODUCT_DETAILS_RELOAD = "FETCH_PRODUCT_DETAILS_RELOAD";
export const FETCH_PRODUCT_DETAILS_RELOAD_DONE =
  "FETCH_PRODUCT_DETAILS_RELOAD_DONE";

export const FETCH_UNDETAILED_CART_REQUEST = "FETCH_UNDETAILED_CART_REQUEST";
export const FETCH_UNDETAILED_CART = "FETCH_UNDETAILED_CART";
export const FETCH_UNDETAILED_CART_SUCCESS = "FETCH_UNDETAILED_CART_SUCCESS";
export const FETCH_UNDETAILED_CART_FAIL = "FETCH_UNDETAILED_CART_FAIL";
export const FETCH_UNDETAILED_CART_ERROR = "FETCH_UNDETAILED_CART_ERROR";
export const FETCH_UNDETAILED_CART_RELOAD = "FETCH_UNDETAILED_CART_RELOAD";
export const FETCH_UNDETAILED_CART_RELOAD_DONE =
  "FETCH_UNDETAILED_CART_RELOAD_DONE";

export const CHECK_DELIVERY_AVAILABILITY_REQUEST =
  "CHECK_DELIVERY_AVAILABILITY_REQUEST";
export const CHECK_DELIVERY_AVAILABILITY = "CHECK_DELIVERY_AVAILABILITY";
export const CHECK_DELIVERY_AVAILABILITY_SUCCESS =
  "CHECK_DELIVERY_AVAILABILITY_SUCCESS";
export const CHECK_DELIVERY_AVAILABILITY_ERROR =
  "CHECK_DELIVERY_AVAILABILITY_ERROR";
export const CHECK_DELIVERY_AVAILABILITY_FAIL =
  "CHECK_DELIVERY_AVAILABILITY_FAIL";
export const CHECK_DELIVERY_AVAILABILITY_RELOAD =
  "CHECK_DELIVERY_AVAILABILITY_RELOAD";
export const CHECK_DELIVERY_AVAILABILITY_RELOAD_DONE =
  "CHECK_DELIVERY_AVAILABILITY_RELOAD_DONE";

export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_ERROR = "ADD_TO_CART_ERROR";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL";
export const ADD_TO_CART_RELOAD = "ADD_TO_CART_RELOAD";
export const ADD_TO_CART_RELOAD_DONE = "ADD_TO_CART_RELOAD_DONE";

export const FETCH_SELLER_PROFILE_REQUEST = "FETCH_SELLER_PROFILE_REQUEST";
export const FETCH_SELLER_PROFILE = "FETCH_SELLER_PROFILE";
export const FETCH_SELLER_PROFILE_SUCCESS = "FETCH_SELLER_PROFILE_SUCCESS";
export const FETCH_SELLER_PROFILE_ERROR = "FETCH_SELLER_PROFILE_ERROR";
export const FETCH_SELLER_PROFILE_FAIL = "FETCH_SELLER_PROFILE_FAIL";
export const FETCH_SELLER_PROFILE_RELOAD = "FETCH_SELLER_PROFILE_RELOAD";
export const FETCH_SELLER_PROFILE_RELOAD_DONE =
  "FETCH_SELLER_PROFILE_RELOAD_DONE";

export const FETCH_OFFER_TAC_REQUEST = "FETCH_OFFER_TAC_REQUEST";
export const FETCH_OFFER_TAC = "FETCH_OFFER_TAC";
export const FETCH_OFFER_TAC_SUCCESS = "FETCH_OFFER_TAC_SUCCESS";
export const FETCH_OFFER_TAC_ERROR = "FETCH_OFFER_TAC_ERROR";
export const FETCH_OFFER_TAC_FAIL = "FETCH_OFFER_TAC_FAIL";
export const FETCH_OFFER_TAC_RELOAD = "FETCH_OFFER_TAC_RELOAD";
export const FETCH_OFFER_TAC_RELOAD_DONE = "FETCH_OFFER_TAC_RELOAD_DONE";

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_ERROR = "FETCH_PRODUCTS_ERROR";
export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL";
export const FETCH_PRODUCTS_RELOAD = "FETCH_PRODUCTS_RELOAD";
export const FETCH_PRODUCTS_RELOAD_DONE = "FETCH_PRODUCTS_RELOAD_DONE";

export const FETCH_QAA = "FETCH_QAA";
export const FETCH_QAA_REQUEST = "FETCH_QAA_REQUEST";
export const FETCH_QAA_SUCCESS = "FETCH_QAA_SUCCESS";
export const FETCH_QAA_ERROR = "FETCH_QAA_ERROR";
export const FETCH_QAA_FAIL = "FETCH_QAA_FAIL";
export const FETCH_QAA_RELOAD = "FETCH_QAA_RELOAD";
export const FETCH_QAA_RELOAD_DONE = "FETCH_QAA_RELOAD_DONE";

export const FETCH_REVIEWS = "FETCH_REVIEWS";
export const FETCH_REVIEWS_REQUEST = "FETCH_REVIEWS_REQUEST";
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEWS_ERROR = "FETCH_REVIEWS_ERROR";
export const FETCH_REVIEWS_RELOAD = "FETCH_REVIEWS_RELOAD";
export const FETCH_REVIEWS_RELOAD_DONE = "FETCH_REVIEWS_RELOAD_DONE";

export const ADD_SRP = "ADD_SRP";
export const FETCH_SRP = "FETCH_SRP";
export const UPDATE_SRP = "UPDATE_SRP";
export const UPDATE_OLD_SRP = "UPDATE_OLD_SRP";

export const UPDATE_SUGGESTIONS = "UPDATE_SUGGESTIONS";
export const UPDATE_CURRENT_FOCUS = "UPDATE_CURRENT_FOCUS";
export const UPDATE_SRP_SUGGESTIONS = "UPDATE_SRP_SUGGESTIONS";
export const REMOVE_SRP = "REMOVE_SRP";
export const VIEW_ALL_SRP = "VIEW_ALL_SRP";
export const UPDATE_DROPDOWN = "UPDATE_DROPDOWN";
export const UPDATE_DROPDOWN_NEW = "UPDATE_DROPDOWN_NEW";
export const UPDATE_ERROR_MESSAGES = "UPDATE_ERROR_MESSAGES";

// ADD_SRP,
// UPDATE_SRP,
// FETCH_SRP,
// UPDATE_OLD_SRP,
// UPDATE_SUGGESTIONS,
// UPDATE_CURRENT_FOCUS,
// UPDATE_SRP_SUGGESTIONS,
// REMOVE_SRP,
// VIEW_ALL_SRP,

export const ADD_ROOMTYPE = "ADD_ROOMTYPE";
export const FETCH_ROOMTYPE = "FETCH_ROOMTYPE";
export const UPDATE_ROOMTYPE = "UPDATE_ROOMTYPE";
export const UPDATE_OLD_ROOM_TYPE = "UPDATE_OLD_ROOM_TYPE";
export const UPDATE_ROOMTYPE_SUGGESTIONS = "UPDATE_ROOMTYPE_SUGGESTIONS";
export const INSERT_ROOMTYPE_SUGGESTIONS = "INSERT_ROOMTYPE_SUGGESTIONS";
export const UPDATE_ROOMTYPE_CURRENT_FOCUS = "UPDATE_ROOMTYPE_CURRENT_FOCUS";
export const REMOVE_ROOMTYPE = "REMOVE_ROOMTYPE";
export const VIEW_ALL_ROOMTYPE = "VIEW_ALL_ROOMTYPE";

export const UPDATE_ROOMTYPE_DROPDOWN_NEW = "UPDATE_ROOMTYPE_DROPDOWN_NEW";
export const UPDATE_ROOMTYPE_DROPDOWN = "UPDATE_ROOMTYPE_DROPDOWN";

export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_OLD_SRP_CLIENT = "UPDATE_OLD_SRP_CLIENT";
export const UPDATE_NEW_SRP_CLIENT = "UPDATE_NEW_SRP_CLIENT";
export const POST_VALUES = "POST_VALUES";
export const UPDATE_ROOMTYPE_ERROR_MESSAGES = "UPDATE_ROOMTYPE_ERROR_MESSAGES";

export const NEXT_SCREEN = "NEXT_SCREEN";
export const TEST = "TEST";
export const POST_EMAIL = "POST_EMAIL";
export const POST_NAME = "POST_NAME";

export const REMOVE_PROP = "REMOVE_PROP";
export const UPDATE_PROPS_SUGGESTIONS = "UPDATE_PROPS_SUGGESTIONS";
export const UPDATE_PROPS_DROPDOWN = "UPDATE_PROPS_DROPDOWN";
export const UPDATE_PROPS = "UPDATE_PROPS";
export const UPDATE_PROPS_NEW = "UPDATE_PROPS_NEW";

export const POST_START_DATE = "POST_START_DATE";
export const POST_END_DATE = "POST_END_DATE";
export const POST_LOCATION = "POST_LOCATION";
export const CLEAR_ERROR_MESSAGES =  "CLEAR_ERROR_MESSAGES";
export const MOVE_RESULT = "MOVE_RESULT";
export const UPDATE_CLIENT_DETAILS  = "UPDATE_CLIENT_DETAILS";
export const SHOW_DROPDOWN  = "SHOW_DROPDOWN";
export const HIDE_DROPDOWN  = "HIDE_DROPDOWN";
export const SHOW_CI_LOADING = "SHOW_CI_LOADING";
export const HIDE_CI_LOADING = "HIDE_CI_LOADING";

export const UPDATE_CLIENT_DETAILS_NEW = "UPDATE_CLIENT_DETAILS_NEW";
export const POST_FLOW = "POST_FLOW";
export const POST_FILE = "POST_FILE";
export const POST_ROW = "POST_ROW";
export const CLEAR_ROWS = "CLEAR_ROWS";
export const UPDATE_MOVE_RESULT = "UPDATE_MOVE_RESULT";
export const UPDATE_PROPS_SELECTION = "UPDATE_PROPS_SELECTION";
export const UPDATE_OLD_SRP_DESC = "UPDATE_OLD_SRP_DESC";
export const UPDATE_OLD_ROOMTYPE_DESC = "UPDATE_OLD_ROOMTYPE_DESC";
export const UPDATE_OLD_ROOMTYPE_NAME = "UPDATE_OLD_ROOMTYPE_NAME";
export const POST_SRP_ROW = "POST_SRP_ROW";
export const CLEAR_SRP_ROWS = "CLEAR_SRP_ROWS";
export const UPDATE_SRP_UPLOAD_RESULT = "UPDATE_SRP_UPLOAD_RESULT";
export const POST_SRP_FILE = "POST_SRP_FILE";
export const POST_ROOMTYPE_ROW = "POST_ROOMTYPE_ROW";
export const POST_ROOMTYPE_FILE = "POST_ROOMTYPE_FILE";
export const CLEAR_ROOMTYPE_ROWS = "CLEAR_ROOMTYPE_ROWS";
export const UPDATE_ROOMTYPE_UPLOAD_RESULT = "UPDATE_ROOMTYPE_UPLOAD_RESULT";
export const POST_BULKCANCEL_ROW = "POST_BULKCANCEL_ROW";
export const CLEAR_BULKCANCEL_ROWS = "CLEAR_BULKCANCEL_ROWS";
export const POST_BULKCANCEL_FILE = "POST_BULKCANCEL_FILE";
export const UPDATE_BULKCANCEL_UPLOAD_RESULT = "UPDATE_BULKCANCEL_UPLOAD_RESULT";
export const UPDATE_RESULT_TABLE_ERROR = "UPDATE_RESULT_TABLE_ERROR";
export const UPDATE_RESULT_TABLE_ANYTEXT = "UPDATE_RESULT_TABLE_ANYTEXT";
export const UPDATE_RESULT_TABLE_ORDER = "UPDATE_RESULT_TABLE_ORDER";
export const UPDATE_RESULT_FAIL_TABLE_TEXT = "UPDATE_RESULT_FAIL_TABLE_TEXT";
export const UPDATE_RESULT_SUCCESS_TABLE_TEXT = "UPDATE_RESULT_SUCCESS_TABLE_TEXT";
export const UPDATE_RESULT_UPLOAD_TABLE_TEXT = "UPDATE_RESULT_UPLOAD_TABLE_TEXT";
export const UPDATE_RESULT_ROOMTYPE_TABLE_TEXT = "UPDATE_RESULT_ROOMTYPE_TABLE_TEXT";
export const UPDATE_RESULT_SRP_TABLE_TEXT = "UPDATE_RESULT_SRP_TABLE_TEXT";
export const UPDATE_RESULT_BULKCANCEL_TABLE_TEXT = "UPDATE_RESULT_BULKCANCEL_TABLE_TEXT";
export const CLEAR_FILTER_ARRAY = "CLEAR_FILTER_ARRAY";

  


