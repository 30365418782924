import React from "react";
import Layout1 from "./Layout1/Layout1";
import Layout2 from "./Layout2/Layout2";
import { fetchSrp, postName, postEmail, save } from "../actions/postActions2";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";

import ReactDOM from "react-dom";
import "../App.css";

//import style
import "../css/pe-icon-7.css";
import "../css/materialdesignicons.min.css";
import "../scss/theme.scss";
import "../css/style.css";

class App extends React.Component {
  constructor() {
    super();
    this.state = { name: "a", email: "" };
  }

  onFormSubmit = (event) => {
    event.preventDefault();
    console.log("in onFormSubmit  save>>>");
    this.props.save({ action: "save" });
  };

  attachNode(node) {
    this._form = ReactDOM.findDOMNode(node);
  }

  onNameChangeHandler = (event) => {
    console.log(event.target.value);
    this.props.postName({ name: event.target.value });
  };

  onEmailChangeHandler = (event) => {
    this.props.postEmail({ email: event.target.value });
  };

  componentDidUpdate(prevProps, prevState) {

  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          {/* <Route exact path="/" component={Home} /> */}
          <Route path="/" component={Layout1} />
          <Route path="/layout2" component={Layout2} />
          {/* <Route path="/home" component={FileUpload} /> */}
          {/* <Route path="/fileupload" component={FileUpload} />
          <Route path="/srpupload" component={SRPFileUpload} />
          <Route path="/roomtypeupload" component={RoomTypeFileUpload} />
          <Route path="/bulkcancelupload" component={BulkCancelFileUpload} />
          
          <Route path="/prop" component={PropCodes} /> */}
          {/* <Route path="/init" component={Home} /> */}
          {/* <Route path="/srp" component={SrpUpload} />
          <Route path="/roomtypes" component={RoomTypeUpload} />
          <Route path="/dates" component={Dates} />
          <Route path="/move" component={Move} /> */}
          {/* <Route path="/confirm" component={Confirm} /> */}
          {/* <Route path="/session" component={Session} /> */}
          {/* <Route path="/location" component={Location} /> */}
          {/* <Route path="/viewstays" component={ViewStays} /> */}
          {/* <Route path="/historyview" component={History} /> */}
          {/* <Route path="/choice" component={Choice} /> */}
          {/* <Route path="/homelocation" component={Home} /> */}
          {/* <Route path="/homelocation" component={Home} /> */}
          
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  item: state.postsReduserName.item,
  name: state.postsReduserName.name,
  email: state.postsReduserName.email,
  nextScreen: state.postsReduserName.nextScreen,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchSrp: (payload) => {
      dispatch(fetchSrp(payload, ownProps));
    },
    postName: (payload) => {
      dispatch(postName(payload, ownProps));
    },
    postEmail: (payload) => {
      dispatch(postEmail(payload, ownProps));
    },
    save: (payload) => {
      dispatch(save(payload, ownProps));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
