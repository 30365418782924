import React, { Component } from 'react'

import home1 from '../../images/home-2-img.png';
// import home2 from '../../images/home-2-bg.png';
import home2 from '../../images/homr-2-bg-bottom.png';

import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { withRouter } from "react-router-dom";
import { fetchSrp, postName, postEmail, save } from "../../actions/postActions";
import { connect } from "react-redux";

class Section extends Component {

  createUI() {
    return this.props.landingData.map((el, i) => {
      return (
        <div key={i}>
         
         {el.value && el.type=="HEADING"? <div> <h1 className="text-white-header fw-normal">{el.value}</h1></div>: <p className="text-white-70 mt-4 f-15 mb-0 text-justify">{el.value}</p>}
         
        </div>
      );
    });
  }


  render() {
    const imageLandingPage = this.props.imagesData.find(function (imgObj) {
      return imgObj.image_name=='landing';
  });
    const imageLandingbgPage = this.props.imagesData.find(function (imgObj) {
      return imgObj.image_name=='landing_bg';
  });
  const imageLandingCircle = this.props.imagesData.find(function (imgObj) {
    return imgObj.image_name=='landing_circle';
  });

  const imageClientBg = this.props.imagesData.find(function (imgObj) {
    return imgObj.image_name=='client_bg';
});
  
    return (
      <React.Fragment>
   
        {/* <section style={{backgroundImage: "url(" + "https://baarbaarnyc.com/wp-content/uploads/2018/03/baarbaar-privateevents-header.jpg" + ")"}} className="section home-2-bg" id="home"> */}
        
         
              <Container>
              
<div class="featuredImage"> 
							
				{/* <div class="overlay"></div> */}
        <div class="contentCenter">
					  <div class="tableCell"><p>A modern interpretation of regional Indian Cuisine crafted by Chef Sreekanth Gaddam</p>
        </div>
				</div>
</div>
                  
              </Container>


      </React.Fragment>
    )
  }
}
//export default Layout_1;
const mapStateToProps = (state) => ({
  item: state.postsReduserName.item,
  name: state.postsReduserName.name,
  email: state.postsReduserName.email,
  nextScreen: state.postsReduserName.nextScreen,
  landingData: state.postsReduserName.landingData,
  imagesData: state.postsReduserName.imagesData,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchSrp: (payload) => {
      dispatch(fetchSrp(payload, ownProps));
    },
    postName: (payload) => {
      dispatch(postName(payload, ownProps));
    },
    postEmail: (payload) => {
      dispatch(postEmail(payload, ownProps));
    },
    save: (payload) => {
      dispatch(save(payload, ownProps));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section));