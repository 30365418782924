import {
  FETCH_POSTS,
  NEW_POST,
  DECREMENT,
  INCREMENT,
  ADD_SRP,
  UPDATE_SRP,
  FETCH_SRP,
  UPDATE_OLD_SRP,
  UPDATE_SUGGESTIONS,
  UPDATE_CURRENT_FOCUS,
  UPDATE_SRP_SUGGESTIONS,
  REMOVE_SRP,
  VIEW_ALL_SRP,
  ADD_ROOMTYPE,
  FETCH_ROOMTYPE,
  UPDATE_ROOMTYPE,
  UPDATE_OLD_ROOM_TYPE,
  UPDATE_ROOMTYPE_SUGGESTIONS,
  INSERT_ROOMTYPE_SUGGESTIONS,
  UPDATE_ROOMTYPE_CURRENT_FOCUS,
  REMOVE_ROOMTYPE,
  VIEW_ALL_ROOMTYPE,
  UPDATE_DROPDOWN,
  UPDATE_DROPDOWN_NEW,
  UPDATE_ERROR_MESSAGES,
  UPDATE_ROOMTYPE_DROPDOWN_NEW,
  UPDATE_ROOMTYPE_DROPDOWN,
  UPDATE_CLIENT,
  UPDATE_NEW_SRP_CLIENT,
  UPDATE_OLD_SRP_CLIENT,
  POST_NAME,
  POST_EMAIL,
  NEXT_SCREEN,
  TEST,
  UPDATE_ROOMTYPE_ERROR_MESSAGES,
  REMOVE_PROP,
  UPDATE_PROPS_SUGGESTIONS,
  UPDATE_PROPS_DROPDOWN,
  UPDATE_PROPS,
  UPDATE_PROPS_NEW,
  UPDATE_PROPS_SELECTION
} from "../actions/types";

const initialState = {
  propCodes: [],
  propCodesNew: [{ fromProp: "", toProp: "" }],
  propCodeSelection: [{ propCode: "" }],
  propSuggestionDetails: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REMOVE_PROP:
      console.log("REMOVE_PROP >>>>>");
      console.log(action);
      console.log("REMOVE_PROP  print exiasting >>>>>");
      var array = [...state.propCodesNew]; // make a separate copy of the array
      console.log(array);

      console.log("INDEX >>>>>>>>>>");
      console.log(action.index);
      if (action.index != -1) {
        array.splice(action.index, 1);
      }
      console.log("after delete array>>>>>");
      console.log(array);
      return {
        ...state,
        propCodesNew: array,
      };

    case UPDATE_CURRENT_FOCUS:
      const newCf = state.currentFocus;
      newCf.name = action.name;
      newCf.row = action.index;
      return {
        ...state,
        currentFocus: newCf,
      };

    case UPDATE_PROPS_SUGGESTIONS:
      console.log("INSIDE UPDATE_PROPS_SUGGESTIONS >>>>>>>>>>>>>");
      console.log(action.value);
      const propCodes = state.propCodes;
      console.log("propCodes print >>>");
      console.log(propCodes);
      const filter = propCodes.filter(
        (condition) =>
          typeof condition.propCode == "string" &&
          action.value.length > 0 &&
          condition.propCode.indexOf(action.value) > -1
      );
      console.log("UPDATE_PROPS_SUGGESTIONS >>>>>");
      console.log(filter);
      return {
        ...state,
        suggestions: filter,
      };

    case UPDATE_PROPS_DROPDOWN:
      console.log("UPDATE_PROPS_DROPDOWN >>>>>");
      console.log("action.value is : " + action.value);

      let newFilterProps = state.propCodes;
      console.log(
        "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< UPDATE_PROPS_DROPDOWN >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
      );
      console.log(newFilterProps);
      const newPropsList = state.propCodes;
      var inputNewSrp = action.value.split(/[\s,(]+/);
      if (inputNewSrp[0].length > 0) {
        console.log("In if UPDATE_PROPS_DROPDOWN >>>>>");

        newFilterProps = newPropsList.filter(
          (condition) =>
            typeof condition.propCode == "string" &&
            inputNewSrp[0].length > 0 &&
            condition.propCode
              .toLowerCase()
              .startsWith(inputNewSrp[0].toLowerCase())
        );
        console.log("UPDATE_PROPS_DROPDOWN >>>>>");
        console.log(newFilterProps);
      } else {
        console.log("In else UPDATE_PROPS_DROPDOWN >>>>>");
      }
      console.log("Filter PROPS >>>>>>>>");
      console.log(newFilterProps);
      return {
        ...state,
        propSuggestionDetails: newFilterProps,
      };

    case UPDATE_PROPS:
      console.log("UPDATE_PROPS >>>>>");
      console.log(state.propCodes);
      console.log("UPDATE SUGGESTION DETAILS PROPS >>>>>");
      console.log(state.propSuggestionDetails);
      
      console.log("UPDATE_PROPS print all coming from browser>>>>>");
      console.log(action.value);
      console.log(action.index);
      console.log(action.rowId);
      console.log("AFTER SELECT UPDATE_PROPS>>>>>>>>>>> >>>>>");
      console.log(state.propSuggestionDetails[action.index]);
      console.log("SRP Code>>>>>>>>>>> >>>>>");

      var selectedSuggestion = state.propSuggestionDetails[action.index];
      var propsArray = [...state.propCodesNew];
      console.log("SRP full UPDATE_PROPS>>>>>>>>>>> >>>>>");
      console.log(propsArray);
      console.log("SRP rowId UPDATE_PROPS>>>>>>>>>>> >>>>>");
      console.log(action.rowId);
      console.log(propsArray[action.rowId]);
      propsArray[action.rowId].fromProp = action.value.toUpperCase();
      console.log("UPDATE_PROPS before set client Id....");
      var duplicatePropCodes = state.propCodes;

      var propObject = null;
      if(action.value.length>=4) 
      {
      propObject = duplicatePropCodes.find(x => x.propCode === action.value.toUpperCase());
      console.log("Prop Name from object"); 
      console.log(propObject);
      if(propObject)
      {
      console.log(propObject.name)
      propsArray[action.rowId].fromPropName = propObject.name;
      }
      }
      if (selectedSuggestion) {
        console.log("propsInput selectedSuggestion>>>>>>>>>>> >>>>>");
        console.log(selectedSuggestion);
        propsArray[action.rowId].fromProp = selectedSuggestion.propCode.toUpperCase();
        propsArray[action.rowId].fromPropCRSFlag = selectedSuggestion.crsFlag;
        propsArray[action.rowId].fromPropName = selectedSuggestion.name;
        propsArray[action.rowId].fromPropCurrencyCode = selectedSuggestion.localization.currencyCode;
        console.log("propsArray after delete....");
        console.log(propsArray);
      }
      console.log("propsArray print before print....");
      console.log(propsArray);
      return {
        ...state,
        propCodesNew: [...propsArray],
      };


      case UPDATE_PROPS_SELECTION:
        console.log("UPDATE_PROPS_SELECTION >>>>>");
        console.log(state.propCodes);
        console.log("UPDATE SUGGESTION DETAILS PROPS >>>>>");
        console.log(state.propSuggestionDetails);
        
        console.log("UPDATE_PROPS print all coming from browser>>>>>");
        console.log(action.value);
        console.log(action.index);
        console.log(action.rowId);
        console.log("AFTER SELECT UPDATE_PROPS>>>>>>>>>>> >>>>>");
        console.log(state.propSuggestionDetails[action.index]);
        console.log("SRP Code>>>>>>>>>>> >>>>>");
  
        var selectedSuggestion = state.propSuggestionDetails[action.index];
        var propsArray = [...state.propCodeSelection];
        console.log("SRP full UPDATE_PROPS>>>>>>>>>>> >>>>>");
        console.log(propsArray);
        console.log("SRP rowId UPDATE_PROPS>>>>>>>>>>> >>>>>");
        console.log(action.rowId);
        console.log(propsArray[action.rowId]);
        propsArray[action.rowId].propCode = action.value.toUpperCase();
        console.log("UPDATE_PROPS before set client Id....");
        var duplicatePropCodes = state.propCodes;
  
        var propObject = null;
        if(action.value.length>=4) 
        {
        propObject = duplicatePropCodes.find(x => x.propCode === action.value.toUpperCase());
        console.log("Prop Name from object"); 
        console.log(propObject);
        if(propObject)
        {
        console.log(propObject.name)
        propsArray[action.rowId].propName = propObject.name;
        }
        }
        if (selectedSuggestion) {
          console.log("propsInput selectedSuggestion>>>>>>>>>>> >>>>>");
          console.log(selectedSuggestion);
          propsArray[action.rowId].propCode = selectedSuggestion.propCode.toUpperCase();
          propsArray[action.rowId].PropCRSFlag = selectedSuggestion.crsFlag;
          propsArray[action.rowId].propName = selectedSuggestion.name;
          propsArray[action.rowId].propCurrencyCode = selectedSuggestion.localization.currencyCode;
          console.log("propsArray after delete....");
          console.log(propsArray);
        }
        console.log("propsArray print before print....");
        console.log(propsArray);
        return {
          ...state,
          propCodeSelection: [...propsArray],
        };

    case UPDATE_PROPS_NEW:
      console.log("UPDATE_PROPS >>>>>");

      console.log(action.value);
      console.log("AFTER SELECT UPDATE_PROPS>>>>>>>>>>> >>>>>");
      console.log(state.propSuggestionDetails[action.index]);
      console.log("SRP Code>>>>>>>>>>> >>>>>");

      var selectedSuggestion = state.propSuggestionDetails[action.index];
      var propsArray = [...state.propCodesNew];
      console.log("SRP full UPDATE_PROPS>>>>>>>>>>> >>>>>");
      console.log(propsArray);
      console.log("SRP rowId UPDATE_PROPS>>>>>>>>>>> >>>>>");
      console.log(action.rowId);
      console.log(propsArray[action.rowId]);
      propsArray[action.rowId].toProp = action.value.toUpperCase();
      console.log("UPDATE_PROPS before set client Id....");
      var duplicateToPropCodes = state.propCodes;

      var propToObject = null;
      if(action.value.length>=4) 
      {
      propToObject = duplicateToPropCodes.find(m => m.propCode === action.value.toUpperCase());
      console.log("To Prop Name from object"); 
      console.log(propToObject);
      if(propToObject)
      {
      console.log(propToObject.name)
      propsArray[action.rowId].toPropName = propToObject.name;
      }
      }
      if (selectedSuggestion) {
        console.log("propsInput >>>>>>>>>>> >>>>>");
        console.log(selectedSuggestion);
        propsArray[action.rowId].toProp = selectedSuggestion.propCode.toUpperCase();
        propsArray[action.rowId].toPropCRSFlag = selectedSuggestion.crsFlag;
        propsArray[action.rowId].toPropName = selectedSuggestion.name;
        propsArray[action.rowId].toPropCurrencyCode = selectedSuggestion.localization.currencyCode;

        console.log("propsArray after delete....");
        console.log(propsArray);
      }
      console.log("propsArray print before print....");
      console.log(propsArray);
      return {
        ...state,
        propCodesNew: [...propsArray],
      };

    default:
      return state;
  }
}

const removeProperty = (obj, newItem, property) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (key !== property) {
      return { ...acc, [key]: obj[key] };
    }
    return { ...acc, slot: newItem };
  }, {});
};

const addProperty = (obj, property) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (key !== property) {
      return { ...acc, [key]: obj[key] };
    }
    return acc;
  }, {});
};
