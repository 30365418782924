import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

//Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from '../images/clients/img-client.jpg';
import img2 from '../images/clients/img-2.jpg';
import img3 from '../images/clients/img-3.jpg';
import img4 from '../images/clients/img-4.jpg';
import img5 from '../images/clients/img-5.jpg';
import img6 from '../images/clients/img-6.jpg';
import quotes from '../images/quote-img.png';
import { withRouter } from "react-router-dom";
import { fetchSrp, postName, postEmail, save } from "../actions/postActions";
import { connect } from "react-redux";

class Clients extends Component {
    createUIItems() {
        return this.props.clientData.map((el, i) => {
          return (
            <>
            {el.clients_type && el.clients_type=="ITEM"? 
                                      
                                      <div>
                                      <div className="testi-content">
                                          <div className="testi-box mt-4">
                                              <h4 className="text-white mb-3 fw-light">{el.clients_title}</h4>
                                              <p className="text-white-70 fw-light mb-0 f-15">{el.clients_desc}</p>
                                              <div className="quote-img">
                                                  <img src={quotes} alt="" className="img-fluid" />
                                              </div>
                                          </div>
                                          <div className="mt-2">
                                              <div className="float-end ms-3">
                                                  <div className="client-img">
                                                      <img src={el.cleints_person_img} alt="" className="img-fluid rounded-circle" />
                                                  </div>
                                              </div>
                                              <div className="clients-name text-end pt-3">
                                                  <h6 className="text-white fw-normal position-relative f-17 mb-0"><span
                                                      className="after-border"></span> {el.cleints_person_name}</h6>
                                                  <p className="text-white-70 f-13 mb-0">{el.cleints_person_title}</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                   
        : ""}
        </>                   
          );
        });
      }
    render() {
        const imageClientBg = this.props.imagesData.find(function (imgObj) {
            return imgObj.image_name=='client_bg';
        });
        var settings = {
            dots: true,
            arrows: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
                {
                  breakpoint: 991.98,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };
        return (
            <React.Fragment>
                {/* <section style={{backgroundImage: "url(" + imageClientBg.image_location + ")"}} className="section" id="clients"> */}
                <section style={{backgroundImage: "url(" + "https://baarbaarnyc.com/wp-content/uploads/2018/03/baarbaar-food-2.jpg" + ")"}} className="section clients-bg-fixed" id="home">
                
                    <div className="bg-overlay"></div>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="title-heading mb-5">
                                    <h3 className="text-white mb-1 fw-light text-uppercase">Customer Reviews</h3>
                                    <div className="title-border-color position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Slider {...settings} className="client-slider">
                                    {this.createUIItems()}
                                   
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    item: state.postsReduserName.item,
    name: state.postsReduserName.name,
    email: state.postsReduserName.email,
    nextScreen: state.postsReduserName.nextScreen,
    clientData: state.postsReduserName.clientData,
    imagesData: state.postsReduserName.imagesData,
  });
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      fetchSrp: (payload) => {
        dispatch(fetchSrp(payload, ownProps));
      },
      postName: (payload) => {
        dispatch(postName(payload, ownProps));
      },
      postEmail: (payload) => {
        dispatch(postEmail(payload, ownProps));
      },
      save: (payload) => {
        dispatch(save(payload, ownProps));
      },
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Clients));