import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import FooterLink from './FooterLink';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [
                {
                    id: 1,
                    title: "",
                    img: "https://cdn.restrozap.com/42/images/restaurant/logo/logo.png",
                    imgLink: "https://cdn.restrozap.com/42/images/restaurant/logo/logo.png",
                    child: [
                      
                        { text: "© 2024 Indian Monsoon" },
                        { text: "Site by LLPL"},

                    ],
                },
                {
                    id: 2,
                    title: "DINING HOURS",
                    img:"",
                    child: [
                        { text: "Dinner"},
                        { text: "Tuesday to Thursday, and"},
                        { text: "Sunday: 5:00pm to 11:00pm" },
                        { text: "Friday: 5:00pm to 12:00am"},
                        { text: "Saturday: 5:30pm to 12:00am"},
                        { text: "Sunday: 5:30pm to 11:00pm"},
                        { text: "Brunch", type:"title_main"},
                        { text: "Saturday & Sunday: 11:30am to 4:00pm"},
                        { text: "(Kitchen closes 30 minutes before the closing time)"},
                        { text: "TAKEOUT & DELIVERY", type:"title_main"},
                        { text: "Dinner", type:"title_sub"},
                        { text: "Tuesday to Sunday: 5:00pm - 9:30pm"},
                       
                    ],
                },
                {
                    id: 3,
                    title: "Location",
                    img:"",
                    child: [
                        { text: "9615 Lockwood road"},
                        { text: "Concord, NC 27703"},
                        { text: "Contact", type:"title_sub" },
                        { text: "704-900-2770"},
                     ],
                },
            ]
        };
    };
    render() {
        return (
            <React.Fragment>
                <section style={{backgroundImage: "url(" + "https://baarbaarnyc.com/wp-content/themes/baarPSD/images/footerTexture.jpg" + ")"}}>
                    <Container>
   
                                <Row>
                                    {this.state.links.map((link, key) => (
                                        
                                        <Col lg={4} sm={4} xs={12} key={key}>
                                            <div>
                                                <p className="text-white fw-normal position-relative f-17 mb-0 para-beauty">{link.title}</p>
                                                <ul className="list-unstyled footer-sub-menu">
                                                {link.img !=null && link.img.length>0 ? (<img src={link.img} height="85px" width="85px" ></img> ):""}
                                                
                                                    {link.child.map((linkItem, key) => (
                                                        <li key={key}>
                                                            {linkItem.link && linkItem.link.length>0 ? ( 
                                                            
                                                            <Link to={linkItem.link} className="text-white fw-normal position-relative">
                                                               {linkItem.type && linkItem.type=="title_sub" ? ( <p className="para-beauty text-white fw-normal position-relative" >{linkItem.text}</p>) : ( <p className="para-beauty text-white fw-normal position-relative" >{linkItem.text}</p>) }
                                                            </Link>
                                                            ) 
                                                            
                                                            : (<>{linkItem.type && linkItem.type=="title_sub" ? ( <p className="para-beauty text-white fw-normal position-relative" >{linkItem.text}</p>) : ( <p className="para-beauty text-white fw-normal position-relative" >{linkItem.text}</p>) }</>)
                                                            
                                                            }
                                                            
                                                       </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                       
                    </Container>
                </section>
                <FooterLink />
            </React.Fragment>
        );
    };
};
