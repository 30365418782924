import { combineReducers } from "redux";
import postReducer from "./postReducer";
import propsReducer from "./propsReducer";

const rootReducer = combineReducers({
  postsReduserName: postReducer,
  propsReduserName: propsReducer,
});

export default rootReducer;
