import React from 'react'

import { hydrate } from 'react-dom'
import { Provider } from 'react-redux';
import App from '../../pages/App';
import store  from '../../pages/store';
import { Router} from "react-router-dom";
import { history } from '../../_helpers';

hydrate(
<Provider store={store}>
<Router history={history}>
        <App />
</Router>
    </Provider>,
    document.getElementById('root')
    
    )
