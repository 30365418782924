import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";
import { fetchSrp, postName, postEmail, save } from "../../actions/postActions";
import { connect } from "react-redux";


import Navbar from "../../components/Navbar/NavBar";
import Section from "./Section2";
import About from "../../components/About";
import Services from "../../components/Services";
import Features from "../../components/Features";
import Pricing from "../../components/Pricing";
import Clients from "../../components/Clients";
import Blog from "../../components/Blog";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer/Footer";

class Layout_2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "about", navheading: "About" },
        { id: 3, idnm: "services", navheading: "Services" },
        { id: 4, idnm: "features", navheading: "Features" },
        { id: 5, idnm: "pricing", navheading: "Pricing" },
        { id: 6, idnm: "clients", navheading: "Clients" },
        { id: 7, idnm: "blog", navheading: "Blog" },
        { id: 7, idnm: "contact", navheading: "Contact Us" },
      ],
      pos: typeof document !== 'undefined'?  document.documentElement.scrollTop :0,
      imglight: true,
      navClass: "navbar-light navbar-custom sticky sticky-dark",
    };
  }
  componentDidUpdate() {
    //console.log("componentDidUpdate >>>>> "+document.documentElement.scrollTop);
  }
  componentDidMount() {
    //alert("componentDidMount >>>>>");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    //alert("componentWillUnmount >>>>>"+document.documentElement.scrollTop);
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = 0;
    if (typeof document !== 'undefined') {
     scrollup = document.documentElement.scrollTop;
    }
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky navbar-light navbar-custom sticky sticky-dark", imglight: false });
    } else {
      this.setState({ navClass: "navbar-light navbar-custom sticky sticky-dark", imglight: true });
    }
  };

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {/* <Suspense fallback={this.PreLoader()}> */}
          {/* Importing Navbar */}
          <Navbar
            navItems={this.props.menuData}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

TEST LAYOUT2
          {/* Importing Services */}
          <Services />

        {/* Importing Footer */}
          <Footer />

        {/* </Suspense> */}
      </React.Fragment>
    );
  }
}
//export default Layout_1;
const mapStateToProps = (state) => ({
  item: state.postsReduserName.item,
  name: state.postsReduserName.name,
  email: state.postsReduserName.email,
  nextScreen: state.postsReduserName.nextScreen,
  menuData: state.postsReduserName.menuData,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchSrp: (payload) => {
      dispatch(fetchSrp(payload, ownProps));
    },
    postName: (payload) => {
      dispatch(postName(payload, ownProps));
    },
    postEmail: (payload) => {
      dispatch(postEmail(payload, ownProps));
    },
    save: (payload) => {
      dispatch(save(payload, ownProps));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout_2));