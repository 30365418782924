import React, { Component } from 'react'

import home1 from '../../images/home-2-img.png';
// import home2 from '../../images/home-2-bg.png';
import home2 from '../../images/homr-2-bg-bottom.png';

import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { withRouter } from "react-router-dom";
import { fetchSrp, postName, postEmail, save } from "../../actions/postActions";
import { connect } from "react-redux";

class Section2 extends Component {

  createUI() {
    return this.props.landingData.map((el, i) => {
      return (
        <div key={i}>
         
         {el.value && el.type=="HEADING"? <div> <h1 className="text-white-header fw-normal">{el.value}</h1></div>: <p className="text-white-70 mt-4 f-15 mb-0 text-justify">{el.value}</p>}
         
        </div>
      );
    });
  }


  render() {
    const imageLandingPage = this.props.imagesData.find(function (imgObj) {
      return imgObj.image_name=='landing';
  });
    const imageLandingbgPage = this.props.imagesData.find(function (imgObj) {
      return imgObj.image_name=='landing_bg';
  });
  const imageLandingCircle = this.props.imagesData.find(function (imgObj) {
    return imgObj.image_name=='landing_circle';
  });

  const imageClientBg = this.props.imagesData.find(function (imgObj) {
    return imgObj.image_name=='client_bg';
});
  
    return (
      <React.Fragment>
        <section style={{backgroundImage: "url(" + imageClientBg.image_location + ")"}} className="section home-2-bg" id="home">
          <div className="home-center">
            <div className="home-desc-center">
              <Container>
                <Row className="align-items-center">
                  <Col lg={5}>
                    <div className="mt-40 home-2-content">
                      
                    {this.createUI()}
                      {/* <h1 className="text-white-header fw-normal">WELCOME TO ACTIVE SQUARES INC
                      </h1>
                      <p className="text-white-70 mt-4 f-15 mb-0 text-justify">
                        {this.props.landingData[0].value}
                        
                     {this.props.item.name}Active Squares Inc combines experience with soft intelligence to provide comprehensive consulting services to wide range of clients. We believe in utilizing technology solutions to maximize business goals to exceed the expectations of our clients. We envision, plan, and execute methodologies to improve business processes, and productivity through process re-engineering, workflow simplification, cost reduction and enhancing reporting capabilities. We value and work towards building trust with our clients by providing our services with integrity, commitment, energy, quality and dedication.
We are able to combine people skills with what the best of today's technology has to offer from a creative point of view. We take pride in having an unusual talent for turning challenges into solutions that can yield profitable results.
                     </p> */}
                      {/* <div className="mt-5">
                        <Link to="#" className="btn btn-custom me-4">Learn More</Link>
                      </div> */}
                    </div>
                  </Col>
                  <Col lg={7}>
                    <div className="mt-40 home-2-content position-relative">
                      <img src={imageLandingPage.image_location} alt=""
                        className="img-fluid mx-auto d-block home-2-img mover-img" />
                      <div className="home-2-bottom-img">
                        <img src={imageLandingCircle.image_location} alt="" className="img-fluid d-block mx-auto" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}
//export default Layout_1;
const mapStateToProps = (state) => ({
  item: state.postsReduserName.item,
  name: state.postsReduserName.name,
  email: state.postsReduserName.email,
  nextScreen: state.postsReduserName.nextScreen,
  landingData: state.postsReduserName.landingData,
  imagesData: state.postsReduserName.imagesData,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchSrp: (payload) => {
      dispatch(fetchSrp(payload, ownProps));
    },
    postName: (payload) => {
      dispatch(postName(payload, ownProps));
    },
    postEmail: (payload) => {
      dispatch(postEmail(payload, ownProps));
    },
    save: (payload) => {
      dispatch(save(payload, ownProps));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section2));