import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//import images
import features from '../images/features-img/img-1.png';
import features2 from '../images/features-img/img-2.png';
import features3 from '../images/features-img/img-3.png';
import { withRouter } from "react-router-dom";
import { fetchSrp, postName, postEmail, save } from "../actions/postActions";
import { connect } from "react-redux";

class Features extends Component {
    createUIItems() {
        return this.props.jobData.map((el, i) => {
          return (
            <>
            {el.jp_type && el.jp_type=="ITEM"? 
                                        <Col lg={6}>
                                        <div className="features-content">
                                            <h4 className="fw-normal text-dark mb-3 mt-4">{el.jp_title}</h4>
                                            <p className="text-muted f-14">{el.jp_subject}</p>
                                            <h4 className="fw-normal text-dark mb-3 mt-4">{el.jp_duties_title}</h4>
                                            <p className="text-muted f-14">{el.jp_duties_desc}</p>
        <h4 className="fw-normal text-dark mb-3 mt-4">{el.jp_skills_title}</h4>
        <p className="text-muted f-14">{el.jp_skills_desc}</p>
        <h6 className="fw-normal text-dark mb-3 mt-4">{el.jp_contact_details}</h6>                               
                                        </div>
                                    </Col>
        : ""}
        </>                   
          );
        });
      }
    render() {
      const imageJobsPage = this.props.imagesData.find(function (imgObj) {
        return imgObj.image_name=='jobs';
    });
    const imageClientBg = this.props.imagesData.find(function (imgObj) {
      return imgObj.image_name=='client_bg';
  });
        return (
            <React.Fragment>
          <section style={{backgroundImage: "url(" + "https://baarbaarnyc.com/wp-content/themes/baarPSD/images/baarBg.jpg" + ")"}} className="section dining-bg-fixed" id="home">

                    <Container>



                    <div class="center">
<h1>Dining</h1>
<p><a href="http://baarbaarnyc.com/wp-content/uploads/2023/12/Dinner-menu-2023-4.pdf" class="button" style={{margin: "15px"}} target="_blank">Dinner Menu</a>     <a href="http://baarbaarnyc.com/wp-content/uploads/2023/04/BB-BRUNCH.pdf" class="button" style={{margin: "15px"}} target="_blank">Brunch Menu</a><br/>
<a href="http://baarbaarnyc.com/wp-content/uploads/2023/12/BBTastingMenu-2023-8.pdf" class="button" style={{margin: "15px"}} target="_blank">Tasting Menu</a>     <a href="http://baarbaarnyc.com/wp-content/uploads/2023/12/BBVeganMenu-11.22.22-3.pdf" class="button" style={{margin: "15px"}} target="_blank">   Vegan Menu</a><br/>
<a href="http://baarbaarnyc.com/wp-content/uploads/2023/12/White-Brown-Simple-Dessert-Menu-1.png" class="button" style={{margin: "15px"}} target="_blank">Dessert Menu</a></p>
<p><strong>Hours</strong><br/>
Dinner: Tue - Sunday 5:00pm - 11:00pm<br/>
Brunch: Saturday – Sunday 12:00pm - 4:00pm<br/>
Closed Mondays.</p>
<h1>Take Out</h1>
<p><a href="http://baarbaarnyc.com/wp-content/uploads/2023/12/Dinner-menu-2023-4.pdf" class="button" target="_blank">View Menu</a></p>
<p><strong>HOURS FOR TAKE OUT &amp; DELIVERY</strong><br/>
Dinner: 5:00pm to 9:30pm.<br/>
Days: Tuesday to Sunday.</p>
<h1>ORDER ONLINE</h1>
<p><a class="chownow-order-online button" href="https://monsoondurham.com/Menu#!" target="_blank">Order Online</a><br/>
&nbsp;</p>
<h1>ORDER FOR PICK UP&nbsp;</h1>
<p><a href="mailto:Delivery@baarbaarnyc.com?subject=Order%20For%20Pick%20Up" >Delivery@indianmonsoon.com</a><br/>
Or Telephone <a href="tel:704-900-2770">704-900-2770</a>
</p></div>
			<div class="clear height45"></div>




                        {/* <Row>
                            <Col lg={12}>
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">LATEST OPENINGS</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            {this.createUIItems()}
                            
                            <Col lg={6}>
                                <div style={{backgroundImage: "url(" + imageClientBg.image_location + ")"}} className="features-img mt-32">
                                    <img src={imageJobsPage.image_location} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row> */}

                    </Container>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    item: state.postsReduserName.item,
    name: state.postsReduserName.name,
    email: state.postsReduserName.email,
    nextScreen: state.postsReduserName.nextScreen,
    jobData: state.postsReduserName.jobData,
    imagesData: state.postsReduserName.imagesData
  });
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      fetchSrp: (payload) => {
        dispatch(fetchSrp(payload, ownProps));
      },
      postName: (payload) => {
        dispatch(postName(payload, ownProps));
      },
      postEmail: (payload) => {
        dispatch(postEmail(payload, ownProps));
      },
      save: (payload) => {
        dispatch(save(payload, ownProps));
      },
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Features));