import React, { Component } from "react";


import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";
// Import Logo
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { fetchSrp, postName, postEmail, navigate } from "../../actions/postActions2";
import { connect } from "react-redux";

//import icon
import FeatherIcon from "feather-icons-react";

import ScrollspyNav from "./Scrollspy";

class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate NavBar>>>>>>>>>>> after save");
    console.log("next screen .....");
    console.log(this.props.nextScreen);
    if (this.props.nextScreen == "layout2") {
      alert(this.props.nextScreen);
      this._form.action = "/layout2";
      this._form.submit();
    }


    // if (this.props.nextScreen == "historyexcel") {
    //   console.log("in if componentDidUpdate next screen");
    //   console.log(this.props.nextScreen);
    //   this._daterangehistoryGetform.action = "/historyexcel";

    //   this._daterangehistoryGetform.submit();
    // } else if (this.props.nextScreen == "cancelhistoryexcel") {
    //   console.log("in if componentDidUpdate next screen");
    //   console.log(this.props.nextScreen);
    //   this._daterangehistoryGetform.action = "/cancelhistoryexcel";

    //   this._daterangehistoryGetform.submit();
    // }
  }

  attachNavFormNode(node) {
    this._form = ReactDOM.findDOMNode(node);
  }

  handleSubmit(event) {
   alert("In handleSubmit >>>>>>>");
  }

  toggle = () => {
    //alert("Toggle is clicked>>>>>");
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  submit = () => {
    //alert("submit");
    event.preventDefault();
        this.props.navigate({ action: "navigate" });   
  };


  submitToExternal = () => {
    alert("Not activated yet..");
  };

  render() {
    const imageLogo= this.props.imagesData.find(function (imgObj) {
      return imgObj.image_name=='logo';
  });
    var targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });
    return (
      <React.Fragment>
        {/* Navbar Start */}
        <form
            ref={this.attachNavFormNode.bind(this)}
            method="POST"
            onSubmit={this.handleSubmit}
          >

        
        <nav
         className={"navbar navbar-expand-lg fixed-top sticky " + this.props.navClass}
        >
          <Container>
            {/* LOGO */}
            {/* <NavbarBrand className="logo" href="/">
              {this.props.imglight === true ? (
                <img src={imageLogo.image_location} alt="Test" height="20" />
                //ACTIVE SQUARES {this.props.logoData} 
                
                // <p className="text-white-logo-100 mt-4 f-15 mb-0 text-justify">
                // ACTIVE {imageLogo.image_location}         
                // </p>
              ) : (
                <img src={imageLogo.image_location} alt="Test" height="20" />
              //   <p className="text-white-logo-100 mt-4 f-15 mb-0 text-justify">
              //  ACTIVE 1{imageLogo.image_location}        
              //   </p>
              )}
            </NavbarBrand> */}

            <NavbarBrand className="logo" href="/">
              {this.props.imglight === true ? (
                <img src="https://cdn.restrozap.com/42/images/restaurant/logo/logo.png" alt="Test" height="85px" width="85px"/>
                //ACTIVE SQUARES {this.props.logoData} 
                
                // <p className="text-white-logo-100 mt-4 f-15 mb-0 text-justify">
                // ACTIVE {imageLogo.image_location}         
                // </p>
              ) : (
                <img src="https://cdn.restrozap.com/42/images/restaurant/logo/logo.png" alt="Test" height="85px" width="85px"/>
              //   <p className="text-white-logo-100 mt-4 f-15 mb-0 text-justify">
              //  ACTIVE 1{imageLogo.image_location}        
              //   </p>
              )}
            </NavbarBrand>

            <NavbarToggler onClick={this.toggle}>
              <i>
                <FeatherIcon icon="menu" />
              </i>
            </NavbarToggler>
            <Collapse
              id="navbarCollapse"
              isOpen={this.state.isOpenMenu}
              className="navbar-collapse"
              delay={1000}
            >
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration="800"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <Nav navbar className="ms-auto navbar-center" id="mySidenav">
                  {this.props.navItems.map((item, key) => (
                    <NavItem onClick={this.toggle}
                      key={key}
                      className={item.navheading === "Home" ? "active" : ""}
                    >
                      <NavLink onClick={this.submit}
                        className={item.navheading === "Home" ? "active" : ""}
                        // href={"#" + item.idnm}
                      >
                        {item.navheading}
                      </NavLink>
                    </NavItem>
                  ))}

                     {/* <NavItem onClick={this.toggle}
                      key="Reserve"
                      className="headerReservation"
                    >
                      <NavLink onClick={this.submit}
                        className="headerReservation"
                        // href={"#" + item.idnm}
                      >
                       Reservation
                      </NavLink>
                    </NavItem> */}
{/* <button type="button" className="button headerReservation">
       <a href="//resy.com/cities/ny/baar-baar" target="_blank" class="button headerReserve">Reservations</a>
      </button> */}
      {/* <a href="https://monsoondurham.com/Menu#!" class="button headerReservation">Reservations</a> */}

      <Link to={"https://monsoondurham.com/Menu#!"} className="button headerReservation" onClick={this.submitToExternal}>Reservations</Link>
      
                </Nav>
              </ScrollspyNav>
            </Collapse>
          </Container>
        </nav>
        </form>
      </React.Fragment>
    );
  }
}
// }
//export default NavbarPage;
const mapStateToProps = (state) => ({
  item: state.postsReduserName.item,
  name: state.postsReduserName.name,
  email: state.postsReduserName.email,
  nextScreen: state.postsReduserName.nextScreen,
  menuData: state.postsReduserName.menuData,
  logoData: state.postsReduserName.logoData,
  imagesData: state.postsReduserName.imagesData,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchSrp: (payload) => {
      dispatch(fetchSrp(payload, ownProps));
    },
    postName: (payload) => {
      dispatch(postName(payload, ownProps));
    },
    postEmail: (payload) => {
      dispatch(postEmail(payload, ownProps));
    },
    navigate: (payload) => {
      dispatch(navigate(payload, ownProps));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavbarPage));